@keyframes siteShimmer {
  100% {
    background-position: 200% 0;
  }
}
.siteShimmer > div {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: siteShimmer 1.5s infinite;
  margin-bottom: 20px;
}

@media only screen and (max-width: 769px) {
  .siteShimmer {
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 0 auto;
    margin-top: 6rem;
  }
  .siteShimmer.loading > div {
    background-position: -200% 0;
  }
  .siteShimmer-heading {
    height: 30px;
    width: 80%;
  }
  .siteShimmer-image {
    min-height: 350px;
    min-width: 100%;
    border-radius: 1rem;
  }
  .siteShimmer-h2 {
    height: 20px;
    width: 100%;
  }
  .siteShimmer-h21 {
    height: 20px;
    width: 30%;
  }
  .siteShimmer-h22 {
    height: 20px;
    width: 50%;
  }
  .siteShimmer-paragraph1 {
    height: 20px;
    width: 60%;
  }
  .siteShimmer-paragraph2 {
    height: 10px;
    width: 70%;
  }
  .siteShimmer-paragraph3 {
    height: 10px;
    width: 80%;
  }
  .siteShimmer-paragraph4 {
    height: 2px;
    width: 100%;
  }
  .siteShimmer-paragraph-large {
    height: 66px;
  }
}

@media only screen and (min-width: 769px) {
  .siteShimmer{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin-top: 7rem;
  }
  .siteShimmer-pcView{
    width: 70vw;
    height: 350px;
    border-radius: 1rem;
  }
  .siteShimmer-heading {
    height: 30vh;
    width:70.5%;
    border-radius: 1rem;
  }
}