/* ::-webkit-scrollbar {
  display: none;
} */

.filters-all {
  display: flex;
  gap: 1rem;
  background-color: white;
  /* background-color: #000; */
}

.filter-menu,
.public-access {
  display: flex;
  border: 1px solid var(--cpogrey);
  border-radius: 0.5rem;
  gap: 1rem;
  align-items: center;
  background-color: #fff;
  height: 2.5rem;
}

.filter-public {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  position: sticky !important;
  top: 4.5rem;
  z-index: 1 !important;
}

.filter-icons:hover {
  border-bottom: 2px solid var(--cpogrey) !important;
  transition: all 0.3s;
}

.selected-filter,
.selected-filter:hover {
  border-bottom: 2px solid var(--cpofontgrey) !important;
  transition: all 0.1s;
  cursor: auto;
}

.public-access {
  min-width: 250px !important;
  margin-right: 3rem;
  padding: 0 0.4rem !important;
}
.filter-rating {
  display: flex;
  gap: 0.5rem;
}

@media only screen and (max-width: 769px) {
  .filters-all {
    display: flex;
    justify-content: flex-end;
  }
  .filters-nav,
  .public-access {
    display: none !important;
  }

  .filter-menu {
    position: fixed !important;
    top: 4rem;
    right: 1.6rem;
  }
  .card-container {
    margin: 4rem 0 5rem 0;
  }

  .preferences-icon {
    position: fixed;
    z-index: 10;
    bottom: 11.6rem;
    right: 0rem;
  }
  div.my-preferences {
    position: fixed;
    bottom: 12.9rem;
    right: 3.7rem;
  }
}

.preferences-icon {
  color: var(--cpotheme);
  padding: 8px;
  border: 0.5px solid var(--cpogrey);
  border-radius: 2rem;
  margin-right: 15px;
  background-color: #fff;
  width: 24px;
  height: 24px;
}

.preferences-container {
  position: relative;
  display: flex;
  align-items: center;
}

.expanded-buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: -165px;
  top: 2;
  background-color: #fff;
  padding: 2px 15px;
  border: 0.5px solid var(--cpogrey);
  border-radius: 2rem;
  transition: left 1s ease-in-out;
}

div.expanded-buttons {
  display: flex;
  gap: 1.5rem;
  text-align: center;
}

span.favBtnSpan:hover {
  cursor: pointer;
}
span.favBtnSpan:hover {
  color: var(--cpotheme);
}

.clearFavouriteBtn {
  color: black !important;
  margin-bottom: -5px;
}
.clearFavouriteBtn:hover {
  width: 26px !important;
  height: 26px !important;
}
.openFavouriteBtn:hover {
  width: 26px !important;
  height: 26px !important;
}

.openFavouriteBtn {
  color: var(--cpotheme) !important;
  margin-bottom: -5px;
}

p.favClearText,
p.favOpenText {
  font-size: 8px;
  margin-bottom: 0px;
}

@media only screen and (min-width: 769px) {
  .preferences-icon:hover {
    background-color: var(--cpotheme);
    color: white;
    box-shadow: 0 0 8px 1px var(--cpotheme);
    cursor: pointer;
  }
}
.preferences-icon:active {
  background-color: rgb(186, 8, 8);
}
