.home-btn {
  position: fixed;
  right: 1.5rem;
  top: 1rem;
  z-index: 6;
  border-radius: 5rem;
  background-color: var(--cpotheme);
  padding: 0.3rem 0.8rem;
  color: #fff;
}
.signin-bottom {
  /* margin-top: 1rem; */
  margin-top: -40px;
  padding: 1rem;
  font-size: small;
}

/* span.login-span {
} */

span.login-span {
  color: var(--cpotheme);
  display: block;
}

.signin-menubar {
    display: flex;
    gap: 5px;
  }
