div.purchasedBody {
  /* width: 100%;
  display: flex;
  justify-content: flex-end; */
  position: absolute;
  right: 50px;
}

div.purchasedContainer {
  width: 100vw;
  max-width: 380px;
  min-width: 350px;
  /* height: 95vh; */
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--cpogrey);
  border-radius: 1rem 0 0 1rem;
  margin: 10px 0;
  background-color: #eaeaea;
}

div.purchasedCardsContainer {
  padding: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 5px;
}

p.purchasedHeading {
  width: 100%;
  background-color: var(--cpotheme);
  color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 1rem 0 0 0;
}

.cancelIconSpan {
  color: #fff;
  margin-top: 2px;
  position: absolute;
  right: 3px !important;
}
.cancelIcon {
  font-size: 18px !important;
  cursor: pointer;
}

div.purchasedCard {
  border-radius: 1rem;
  background-color: #fff;
  /* color: var(--cpofontgrey); */
  text-align: start;
}
