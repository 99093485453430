* {
  margin-top: 0;
  padding: 0;
}

div.centeredContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: 2rem;
  width: 95%;
  box-sizing: border-box;
  margin-top: 3rem;
  margin-bottom: 4rem;
  justify-items: center;
}

.card-item {
  display: flex;
  flex-direction: column;
  width: 320px;
  justify-content: center;
  margin-bottom: 2rem;
}

.set-wishlist {
  color: var(--cpotheme) !important;
}
.card-img {
  width: 320px !important;
  height: 300px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 3px;
  box-sizing: border-box;
  border-radius: 1rem;
}

div.titleRating {
  display: flex;
  justify-content: space-between;
}

h3.rating {
  font-weight: normal !important;
}
.card-title {
  display: flex;
  font-size: medium;
  margin-top: 10px;
  margin-bottom: 5px;
}

div.addToCart {
  display: flex;
  justify-content: flex-end;
  width: 320px;
  margin-right: 0;
}
.single-card-details {
  display: flex;
  max-height: 150px;
  justify-content: space-between;
}

.card-left-li {
  width: 11rem;
  text-align: start;
  font-size: 14px;
  overflow: hidden;
  margin-right: 0px;
}
.card-right-li {
  text-align: start;
  font-size: 14px;
  width: 7.3rem;
  overflow: hidden;
  margin-right: 0;
}

.card-left-li:nth-child(n + 2),
.card-right-li:nth-child(n + 2) {
  font-weight: lighter !important;
  font-size: 12px !important;
  color: var(--cpofontgrey);
  /* color: blue; */
}

.star-icon {
  height: 16px !important;
  margin-left: -5px;
}

.single-card-details > span {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
div.wholeCard {
  margin-right: -20px;
}
span.redText {
  color: var(--cpotheme);
}

li.emptyLiField {
  height: 18px;
}

@media only screen and (min-width: 769px) {
  div.card-container {
    margin-top: 3rem !important;
    margin-bottom: 0rem !important;
  }
  h3.card-title {
    max-height: 20px;
  }
  .addToCart {
    width: 370px;
    margin-top: 0;
  }
  .wishlist-icon {
    position: relative;
    top: 33px;
    left: 290px;
    color: white;
    /* z-index: 1; */
    width: 20px;
    cursor: pointer;
  }
  span.loadMoreBtn {
    position: sticky;
    margin-left: 50%;
  }

  .loadMoreIcon {
    animation: moveUpDown 2s infinite linear;
  }

  .loadMoreIcon:hover {
    animation-play-state: paused;
  }

  @keyframes moveUpDown {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(40px);
    }
  }
}

@media only screen and (max-width: 769px) {
  .card-img {
    width: 22.5rem;
  }
  .wishlist-icon {
    position: relative;
    top: 33px;
    left: 290px;
    color: white;
    width: 20px;
    cursor: pointer;
  }

  .card-right-li {
    text-align: start;
  }
  div.addToCart {
    margin-top: 0;
    width: 320px;
    margin-left: 7px;
    display: flex;
  }
  .single-card-details {
    display: flex;
    max-height: 150px;
    justify-content: space-between;
    width: 320px;
  }
  div.addToCart {
    margin: 0;
  }
  div.card-container {
    width: 320px;
    margin-top: 5rem;
  }
  span.loadMoreBtn {
    position: sticky;
    margin-left: 45%;
  }
}

.loadMoreIcon {
  color: var(--cpotheme);
  padding: 5px;
  border: 1px solid var(--cpotheme);
  border-radius: 50%;
  cursor: pointer;
  margin-top: 10px;
}
div.loadMoreBtn {
  width: 100%;
  display: flex;
  justify-content: center;
}

div.loadMoreComp {
  display: flex;
  flex-direction: column;
  align-items: center;
}
span.totalSitesCount {
  background-color: var(--cpotheme);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 1rem;
}
