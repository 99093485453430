@import url("https://fonts.googleapis.com/css2?family=Comfortaa&family=Didact+Gothic&family=Mulish:wght@200&family=Nunito+Sans:opsz@6..12&family=Nunito:wght@500;600;700&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Didact+Gothic&family=Mulish:wght@200&family=Nunito:wght@500;600;700&display=swap'); */
:root {
  --cpogrey: #ddd;
  --cpofontgrey: #717171;
  --cpotheme: #ea5355;
}

body {
  margin: 0;
  font-family: "Comfortaa", sans-serif;
  font-family: "Didact Gothic", sans-serif;
  font-family: "Mulish", sans-serif;
  font-family: "Nunito", sans-serif;
  font-family: "Nunito Sans", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

/* body,
.navbar, div.selected-filters,
.filter-menu,
.public-access,
.preferences-icon,
.preferences-icon:active,
.footer,
.upper-footer,
.MuiPaper-root, div.mobilePriceCard,
.footer-accordion.expanded,
.footer-accordion.expanded {
  background-color: #1b1b1b !important;
}
ul.search-list{
}
.expandIcon {
  color: #222 !important;
  background-color: var(--cpotheme) !important;
}

.preferences-icon:hover{
  color: var(--cpotheme) !important;
}

.preferences-icon:active{
  color: rgb(158, 6, 6) !important;
}

body, div.selected-filters,
.card-right-li,
.card-left-li,
ul,
li,
.addressDetails,
.siteAccess,
.siteType,
div.siteOtherDetails,
Button,
div.siteDetailL1,
.siteAboutData,
.siteFacilities, h2.whatThisPlaceOffers, .MuiInputLabel-root, .MuiOutlinedInput-root {
  color: var(--cpogrey) !important;
}

.MuiOutlinedInput-root{
  border: 1px solid var(--cpogrey) !important;
} */

.cell {
  border: 1px solid var(--cpogrey) !important;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style-type: none;
}

/* Force scrollbar to be always visible */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: var(--cpotheme);
  border-radius: 1rem;
}

/* Disable tap highlight color on mobile devices */
* {
  -webkit-tap-highlight-color: transparent;
}

.darkModeHidden {
  display: none !important;
}
