Button.checkout-btn {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: var(--cpotheme) !important;
  color: #fff !important;
  border-radius: 5px;
}

Button.cart-button {
  background-color: var(--cpotheme) !important;
}
Button.cart-button:hover {
  background-color: rgb(219, 0, 0) !important;
}
Button.cart-button:active {
  background-color: rgb(236, 14, 14) !important;
}

Button.checkout-btn:hover {
  background-color: rgb(219, 0, 0) !important;
}
Button.checkout-btn:active {
  background-color: rgb(236, 14, 14) !important;
}

div.emptyCartDiv {
  height: 100vh;
  width: 99vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
}

img.emptyCartImg {
  height: 760px;
  width: 100vw;
  filter: brightness(0.9);
}
span.cartIsEmpty {
  position: absolute;
  font-size: 60px;
  font-weight: bolder;
  color: #ffffffba;
  padding: 10px 30px;
  background-color: rgba(0, 0, 0, 0.468);
  border-radius: 3rem;
  text-align: center;
}

Button.goBackBtn {
  border: 4px solid var(--cpotheme);
  height: 45px;
  border-radius: 1rem;
  font-size: 16px;
  font-weight: bold;
  color: var(--cpotheme);
  background-color: rgba(0, 0, 0, 0.663);
}

Button.goBackBtn:hover {
  background-color: rgba(0, 0, 0, 0.423);
  color: var(--cpotheme);
}
Button.goBackBtn:active {
  color: #ffffffba;
  background-color: #000000a8;
}

span.soldOutLogo {
  background-color: #ea5355;
  color: #fff;
  max-height: 18px;
  min-width: 45px;
  padding: 1px 3px;
  border-radius: 5px;
  border: 2px solid rgb(216, 0, 0);
  font-size: 12px;
}

@media only screen and (min-width: 769px) {
  div.cartWindow {
    width: 99vw;
    display: flex;
  }
  .cart-container {
    display: flex;
    justify-content: flex-start;
    margin: 2rem;
    width: 72vw;
    min-width: 360px;
    flex-wrap: wrap;
    margin-top: 5rem;
    margin-bottom: 1rem;
    min-height: 100vh;
    border: 1px solid var(--cpogrey);
    /* border-bottom: 1px solid var(--cpogrey); */
  }

  .cart-item {
    display: flex;
    margin: 2rem;
    /* padding: 2rem; */
    /* width: 580px; */
    /* border-bottom: 1px solid gray; */
    margin-top: 2rem;
  }

  .cartCardItem {
    display: flex;
    width: 28vw;
    min-width: 300px;
    max-width: 450px;
    height: 340px;
    max-height: 350px;
    min-height: 290px;
    flex-shrink: 1;
  }

  .cart-billing {
    width: 130px;
    margin: 2rem;
    position: fixed;
    right: -10px;
    border-right: 2px solid black;
    padding: 1rem;
    bottom: 3rem;
    justify-content: flex-end;
  }
  .clearCart-btn {
    background-color: rgb(74, 74, 74) !important;
  }
  .clearCart-btn:hover {
    color: var(--cpotheme);
  }
  .clearCart-btn:active {
    background-color: rgb(44, 44, 44) !important;
  }

  img.emptyCartImg {
    margin-top: 8rem !important;
    height: 750px;
    width: 100vw;
    z-index: -1;
    object-fit: fill;
  }
  div.emptyCartDiv {
    height: 610px;
    z-index: -1;
  }
  .goBackBtn {
    position: absolute;
    transform: translate(0%, 140%);
    width: 120px;
  }
  div.cartAllPriced {
    min-width: 160px;
    transform: translate(0%, -50%);
    font-weight: bold;
    font-size: 14px;
    opacity: 0.6;
  }
}

@media only screen and (max-width: 769px) {
  div.cartWindow {
    width: 100vw;
    display: flex;
    justify-content: center;
  }
  .cart-container {
    justify-content: center;
    align-items: flex-start;
    margin-top: 5rem;
    margin-bottom: 1rem;
    padding-bottom: 5rem;
    padding-bottom: 2rem;
    min-height: 100vh;
    border: 1px solid var(--cpogrey);
  }

  .cart-item {
    display: flex;
    margin-top: 2rem;
    padding: 0 20px;
  }

  .cartCardItem {
    width: 80vw;
    height: 330px;
  }

  div.detailedPricing {
    display: none;
  }
  .cart-billing {
    position: sticky;
    bottom: 60px;
    background-color: #ffffff;
    margin-bottom: -40px !important;
    height: 100px !important;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid var(--cpogrey);
    z-index: 1;
  }

  img.emptyCartImg {
    position: fixed;
    object-fit: cover;
    height: 100vh;
  }
  .goBackBtn {
    position: fixed;
    top: 65%;
    width: 120px;
  }

  .cartIsEmpty {
    position: fixed !important;
    top: 35%;
  }

  .clearCartBtn {
    position: absolute !important;
    right: 85px;
    /* display: none; */
    position: fixed !important;
    bottom: 70px;
    right: 85px;
    transform: translate(54%, -280%) !important;
    z-index: 999;
    width: 110px;
  }
  div.cartAllPriced {
    margin: 10px;
    margin-left: 25px;
  }

  .clearCart-btn {
    width: 115px !important;
    padding: 0 !important;
    background-color: rgb(74, 74, 74) !important;
  }
  .clearCart-btn:hover {
    color: var(--cpotheme);
  }
  .clearCart-btn:active {
    background-color: rgb(44, 44, 44) !important;
  }
  .checkout-btn-class {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .checkout-btn {
    width: 90vw;
  }
}

.clearCartBtn {
  position: absolute;
  transform: translate(30%, 40%);
}

div.detailedPricing {
  width: 22vw;
  margin-right: 1rem;
}
.cardContent {
  display: flex;
  justify-content: space-between;
}
